import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import * as ContactSuccessStyles from "./../styles/contactSuccess.module.css"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const ContactSuccess = () => (
  <Layout>
    <Seo noindex title="Message sent" />
    <div className={ContactSuccessStyles.container}>
      <div className={ContactSuccessStyles.headings}>
        <h1>Thank you for your enquiry!</h1>
        <h2 className={ContactSuccessStyles.subtitle}>
          We will be back to you shortly.
        </h2>

        <AniLink
          className={ContactSuccessStyles.button}
          fade
          to={"/"}
          aria-label={"Go to the Home page"}
        >
          Go to home
        </AniLink>
      </div>
    </div>
  </Layout>
)

export default ContactSuccess
